import React from "react";
import "./CultivationMushroom.css"; // Import your CSS file for styling
import { onReadMore } from "./Functions";
import Logs_stacked from "./images/Logs_stacked.jpg";
import Logs_stacked_covered_snow from "./images/Logs_stacked_covered_snow.jpg";
import Spawn_run_at_end_of_log from "./images/Spawn_run_at_end_of_log.jpg";
import drillfill from "./images/drill_fill.jpg";
import drillinhand from "./images/drillinhand.jpg";
import inoculatorinhand from "./images/inoculatorinhand.jpg";
import pdf from "./images/pdf.jpg";
import TotemsPDF from "./images/TotemsPDF.png";

const CultivationMushroom = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="mushroom-cultivation-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>
						Cultivation Outdoors on Hardwood Logs
					</h2>
					<h4>There are 2 methods of log inoculation</h4>
					<p id="p" align="justify">
						<span className="b-orange b-bold">‘Drill & Fill’ method:</span> For
						this method you drill holes in the logs and fill with spawn. Then
						the holes are sealed with wax or Styrofoam caps. For Lion’s Mane
						inoculations double the inoculation rate.
						<br />
						<br />
						You will need:
						<br />
						<div className="handtools">
							<div>
								<ul style={{ marginRight: "20px" }}>
									<li>
										One 2.5 kilo bag of sawdust spawn for 20 - 25 four foot logs
										or 1.75 kilo bag for 10-15 logs; for Lion’s Mane double the
										inoculation rate;
									</li>
									<br />
									<li>
										A drill or an angle grinder and a drill bit with a depth
										stop;
									</li>
									<br />
									<li>
										An inoculation tool, and either wax or Styrofoam caps to
										seal inoculation holes.
									</li>
									<br />
									<li>
										We offer an adapter that allows you to mount your bit onto
										an angle grinder which is an inexpensive high-speed
										appliance that you can purchase locally. See the Equipment
										and Supplies page.
									</li>
								</ul>
							</div>
							<div>
								<div className="img-log-stacked-container">
									<img
										src={drillfill}
										width={"100%"}
										alt="‘Drill & Fill’ method"
									/>
									<br />
									‘Drill & Fill’ method
								</div>
							</div>
						</div>
						<br />
						<span className="b-orange b-bold">‘Totem’ method:</span> Suitable
						for larger diameter logs (8 to 12 “) that are about 2.5 to 3’ long.
						In this method the log is sectioned horizontally, and spawn is
						packed between the layers and then the log is
						re-assembled/re-stacked. The totem method works best using Oyster
						and Lion’s Mane spawn. One bag of spawn 1.75 kilograms is enough for
						one or two logs that are 3’ long; 2.5 kilo bag for more logs; you
						can pack the spawn and use all your spawn. You will need a chainsaw
						and a leaf litter bag and a plastic bag.
						<p>
							These two methods can be combined. Use drill & fill method on logs
							that are 3” to 8” in diameter. You can use totem for small trunks
							or large diameter branches 9” to 12”. Drill & fill smaller
							branches first and then use up the remainder of your spawn as a
							totem. This is suitable if you have one or several small trees cut
							– drill & fill the branches and then use up the remainder of the
							spawn for the trunk with the totem method.{" "}
						</p>
						<a
							href="https://acrobat.adobe.com/id/urn:aaid:sc:VA6C2:bff55bef-0eea-4dea-a6a5-de8df3d4c852"
							target="_blank"
						>
							<img src={TotemsPDF} alt="Hand with drill" />
						</a>
					</p>
				</div>
			</div>
			<div
				style={{
					background: "#474747",
					height: "fit-content",
				}}
			>
				<div
					id="small-scale-outdoor-cultivation-container"
					style={{
						margin: "30px 10px 20px 10px",
						position: "relative",
						background: "#474747",
						height: "fit-content",
						overflow: "hidden",
						width: "98%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Plan your project</h2>
					<p id="p" align="justify">
						<span className="b-orange b-bold">Step #1</span>
						<br />
						<span className="b-orange">
							Choose the species of mushroom that you want to grow.
						</span>{" "}
						<ul>
							<li>
								{" "}
								Shiitake, Lentinula edodes - a wide temperature range strain
								which means that it will ‘fruit’ in a wide range of
								temperatures; Shiitake likes cool temperatures, producing
								flushes in early spring and late fall and will also ‘fruit’ in
								warm temperatures following rain/soaking.
							</li>
							<li>
								Oyster, Pleurotus ostreatus, grey dove variety; fruits in warm
								temperatures and high humidity.
							</li>
							<li>
								Lion’s Mane, Hericium erinaceus which likes cool temperatures
								and high humidity*
							</li>
						</ul>
						<span className="b-orange">
							Choose the type/species of wood that is best suited to that
							species.
						</span>
						<br />
						<ul className="ula" style={{ listStyleType: "none" }}>
							<li className="lia">
								Superior: white and red oak; hard/sugar maple Recommended:
								ironwood; beech; alder; Manitoba maple Not recommended: ash and
								fruitwood
							</li>
							<li className="lib">
								Oyster: Superior: Aspen; yellow poplar; Manitoba maple/box
								alder; cottonwoods; willows Recommended species of wood:
								hackberry; mulberry
							</li>
							<li className="lic">
								Lion’s Mane: Superior: Beech; hard/sugar maple; hackberry;
								mulberry Recommended: black, yellow and paper birch; blue beech
								and hornbeam
							</li>
						</ul>
						See the links below and this document for details about log
						selection and cutting
						<br />
						<br />
						<span className="b-orange b-bold">Step #2</span>
						<br />
						<span className="b-orange">Cut the logs.</span> Plan to cut logs in
						winter; identify trees and mark them in the summer/fall. Cut logs
						from healthy living trees when they are dormant - after leaf fall in
						the autumn and before leaf bud in the spring. Best to cut in winter
						months as bark is tighter on the log. Take care to preserve the bark
						on the logs – try not to damage or strip the bark from logs. Cut
						logs & branches can be piled off the ground – on a skid or on other
						logs - in the woodlot/forest for the remainder of the winter in a
						shaded area exposed to snow and rain but protected from direct,
						prolonged exposure to sun. Breathable shade cloth or cedar boughs
						can be used to protect logs from sun. Logs - branches and trunks -
						can be cut into 3’ or 4’ lengths just prior to inoculation; this
						will preserve moisture inside the logs. It is advisable to let logs
						rest for a month after cutting and prior to inoculation.
						<br />
						<br />
						<span className="b-orange b-bold">Step #3</span>
						<br />
						<span className="b-orange">
							Order spawn, tools, and supplies.
						</span>{" "}
						Place your order when you know how many logs you have. Order all
						tools and supplies that you will need. Place your order by March 15
						if possible. Spawn is a living product and is custom ordered and
						will be shipped for ‘just-in-time’ for spring inoculation.
						<br />
						<br />
						<span className="b-orange b-bold">Step #4</span>
						<br />
						<span className="b-orange">Inoculate the logs.</span>
						<br />
						<br />
						<div className="handtools">
							<div className="handtoolsa">
								<div className="img-log-stacked-container">
									<img src={drillinhand} width={"100%"} alt="Hand with drill" />
									<br />
									Hand with drill
								</div>
							</div>
							<div>
								<div className="img-log-stacked-container">
									<img
										src={inoculatorinhand}
										width={"100%"}
										alt="Hand with inoculator"
									/>
									<br />
									Hand with inoculator
								</div>
							</div>
						</div>
						<br />
						Spawn will arrive in late April / early May. Inoculate when ambient
						night temperature is staying above freezing and daytime temperatures
						are consistently above 5 degrees Celsius. [insert picture of a bag
						of spawn with the tools needed] For detailed instructions see the
						Sawdust Spawn Use Instructions in the Information menu. [insert link
						to this instruction sheet]
						<br />
						<br />
						<span className="b-orange b-bold">Step #5</span>
						<br />
						<span className="b-orange">
							Choose the location to stack logs for the spawn run/incubation
							phase.
						</span>{" "}
						Inoculated logs should be stacked off the ground on a skid or blocks
						or on other logs; stack the logs crib style, in a low profile that
						is 4 to 5 logs high, allowing for ventilation around each log. The
						logs will remain here for a year until they are ready to ‘fruit’ the
						following spring so locate them in a low (but not a swampy) shaded
						and sheltered area in the woodlot/forest with access to water
						(sprinkler) and where they will not be hit by harsh winter winds.
						<br />
						<br />
						<div className="img-log-stacked-container">
							<img
								src={Logs_stacked}
								width={"100%"}
								alt="Logs stacked for spawn run / incubation"
							/>
							<br />
							Logs stacked for spawn run / incubation
						</div>
						<br />
						The objective during the spawn run is to keep the moisture level
						inside the logs high but allow the outside to dry so as not to rot
						the bark off. Logs should not be exposed to full sun during the
						spawn run/incubation phase. During winter keep logs shaded but allow
						snow to cover the logs. Even if logs are under the forest canopy,
						they may need to be covered with shade cloth or with some form of
						breathable covering (cedar boughs) so that they are not exposed to
						full/direct sun for hours at a time, especially during the late
						afternoon.
						<br />
						<br />
						<div className="img-log-stacked-container">
							<img
								src={Logs_stacked_covered_snow}
								width={"100%"}
								alt="Logs stacked and covered with snow"
							/>
							<br />
							Logs stacked and covered with snow
						</div>
						<br />
						At the end of the spawn run –generally the following spring - you
						may be able to see the mycelium ‘showing’ on the end of the log in
						the sapwood ring as in the picture below. This indicates that the
						log is colonized and ready to start pinning and to ‘fruit’. At this
						point the logs should be stood up to facilitate mushroom emergence
						and harvesting. When using the ‘totem’ method, inoculated totem logs
						are placed upright, and they do not need to be moved after
						incubation. Totems should be located in a protected spot such as a
						shady garden or along a hedgerow, with access to water, in a spot
						where you can check them on a regular basis.
						<br />
						<br />
						<span className="b-orange b-bold">Step #6</span>
						<br />
						<span className="b-orange">
							Choose location to stand the inoculated logs for harvest the
							following spring.
						</span>
						<br />
						<br />
						<div className="img-log-stacked-container">
							<img
								src={Spawn_run_at_end_of_log}
								width={"100%"}
								alt="Spawn run at end of log"
							/>
							<br />
							Spawn run at end of log
						</div>
						<br />
						Inoculated logs will “fruit” 6 months to 1 year following
						incubation/spawn run. Once logs begin pinning (small bumps visible
						on the surface of the bark that will develop into mushrooms) they
						must be stood up to allow the mushrooms to form and emerge and to
						facilitate harvesting. The ideal location is a well-ventilated south
						facing slope under the forest canopy; stand logs so that they will
						not be hit by wind or exposed to hours of directly sunlight,
						especially in late afternoon. Breathable shade cloth, burlap, or
						cedar boughs can be used to protect logs from sun while allowing
						rain onto logs. There are various ways to stand logs for harvesting
						such as leaning on barbed wire strung between posts or standing
						tepee style around trees; or on split rails to allow logs to be
						‘flipped’ from one rail to another. You will need access to water
						unless you plan to rely on natural rainfall to provoke ‘fruiting’
					</p>
				</div>
			</div>
			<div
				style={{
					background: "#474747",
					height: "fit-content",
				}}
			>
				<div
					id="small-scale-indoor-cultivation-container"
					style={{
						margin: "50px 10px 20px 10px",
						position: "relative",
						background: "#474747",
						height: "fit-content",
						overflow: "hidden",
						width: "98%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>
						Sources of information about Mushroom Cultivation Outdoors on
						Hardwood Logs
					</h2>
					<p id="p" align="justify">
						An excellent source of information is the book “Growing Gourmet and
						Medicinal Mushrooms” by Paul Stamets. Open source online. There is a
						chapter on Lion’s Mane, Oyster, and Shiitake.
						<br />
						<br />A valuable resource is the website of Cornell University
						College of Agriculture and Life Sciences (CornellCALS). There are
						publications and videos. This 57 page document has comprehensive
						information.
						<b>
							{" "}
							<br />
							<a href="https://projects.sare.org/wp-content/uploads/977907shiitake-bmp-3-11-final-for-print-hires.pdf">
								“Best Management Practices for Log-Based Shiitake Cultivation in
								the Northeastern United States” (sare.org)
							</a>
						</b>{" "}
						<br />
						Cornell Small Farms program offers a variety of resources. Look
						here:
						<br />
						<a href="https://smallfarms.cornell.edu/projects/mushrooms/outdoor-production">
							https://smallfarms.cornell.edu/projects/mushrooms/outdoor-production
						</a>
						<br />
						<br />
						Here is a quick link to a document from Cornell University
						Cooperative Extension program
						<br />
						<br />
						<a
							href="https://acrobat.adobe.com/id/urn:aaid:sc:VA6C2:bff55bef-0eea-4dea-a6a5-de8df3d4c852"
							target="_blank"
						>
							<img src={TotemsPDF} alt="Hand with drill" />
						</a>
						<br />
						<br />
						See this booklet from Sustainable Agriculture Research & Education
						Organization (SARE.org)
						<br /> <br />
						<b>
							“Log Based / Forest Shiitake Mushroom Cultivation, Introductory
							Grower’s Guide”
						</b>
						<br />
						<br />
						<a href="https://projects.sare.org/wp-content/uploads/944524log-base-production.pdf">
							Booklet.pub (sare.org)
						</a>
						<br />
						<br />
						There are many videos online about mushroom cultivation:
						<br />
						Search “Growing Mushrooms with the Totem Method” and “How to Grow
						Mushrooms on Logs”
						<br />
						<br />
						These videos are particularly useful:
						<br /> <br />
						<a href="https://cals.cornell.edu/news/four-stages-shiitake-mushroom-cultivation">
							Four Stages of Shiitake Mushroom Cultivation / CALS (cornell.edu)
						</a>
						<br />
						<a href="https://www.youtube.com/watch?v=eDmm52FXfZ4">
							How to Grow Mushrooms on Logs / Complete Inoculation Walkthrough!
							(youtube.com)
						</a>
						<br />
						<a href="https://www.youtube.com/watch?v=MHL4OuJfRic">
							How I'm Growing my own Shiitake Mushrooms (youtube.com)
						</a>
						<br />
					</p>
				</div>
			</div>
			<div
				style={{
					margin: "30px 10px 20px 10px",
					background: "#2D4747472D2D",
					height: "fit-content",
				}}
			></div>
			<div
				style={{
					margin: "30px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>
			&nbsp;
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#474747",
					height: "fit-content",
				}}
			></div>
			<div style={{ height: "50px" }}></div>
		</div>
	);
};
export default CultivationMushroom;
