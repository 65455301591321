import React, { useEffect } from "react";
import "./Card.css";

const Card = (item) => {
	const [itemName, setItemName] = React.useState("");
	const [itemBenefits, setItemBenefits] = React.useState(0);
	const [itemImage, setItemImage] = React.useState("");
	useEffect(() => {
		if (item.mostPopular) {
			setItemName(item.mostPopular.name);
			setItemImage(item.mostPopular.image);
			setItemBenefits(item.mostPopular.benefits);
		}
	}, []);
	return (
		<div className="card-item">
			<img
				style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
				src={itemImage}
				alt=""
			/>
			<div className="card-descriptions">{itemName}</div>
			<div className="card-benefits">{itemBenefits}</div>
		</div>
	);
};

export default Card;
