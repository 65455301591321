const KitsAvailability = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="cultivation-techniques-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
						width: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Kits Availability and Pricing</h2>
					<p style={{ textAlign: "left" }}>
						Sawdust Kits <u>Availability</u>:
						<br />
						<ul>
							<li>
								Sawdust kits are made to order and must be prepaid – see pricing
								below{" "}
							</li>
							<li>
								Kits are living mycelium, so production and shipping dates
								conform to temperature patterns in Canada; we do not ship in
								cold weather below 10C / 50F{" "}
							</li>
							<li>
								We make kits from March until October; We require 4-6 weeks lead
								time for kit orders{" "}
							</li>
							<li>We ship kits from mid-April until mid-October </li>
							<li>
								We ship via Canada Post; we also ship via UPS or FedEx if
								specified{" "}
							</li>
							<li>When you place an order we will send a quote </li>
							<li>
								When you confirm the order we will send an invoice with costs
								including shipping charges and the ship date; payable by
								e-transfer, cheque or paypal; payment details will come with the
								invoice{" "}
							</li>
							<li>
								When the order is paid we will send a receipt • When we ship you
								will receive notification{" "}
							</li>
							<li>
								We offer ‘Pick-up Days’ at Fun Guy Farm for prepaid orders{" "}
							</li>
							<li>See the Ordering section/tab for full details</li>
						</ul>
						<br />
						Sawdust Kits <u>Pricing</u>:
						<br />
						<ul>
							<li>
								Sawdust kits are made for Shiitake, Oyster, Lion’s Man and come
								in 2 sizes{" "}
							</li>
							<li>
								Small size kit is 1.75 kilos
								<br />
								<span style={{ marginLeft: "40px" }}>
									o 1 small kit is $30 + shipping
								</span>
								<br />
								<span style={{ marginLeft: "40px" }}>
									o 2 small kits are $25 each + shipping
								</span>
							</li>
							<li>
								Regular size kit is 2.5 kilos
								<br />
								<span style={{ marginLeft: "40px" }}>
									o 1 regular kit is $45 + shipping
								</span>
								<br />
								<span style={{ marginLeft: "40px" }}>
									o 2 regular kits are $40 each +shipping
								</span>
							</li>
							<li>
								Larger and Wholesale orders for kits are FOB Fun Guy Farm;
								contact us for quote{" "}
							</li>
							<li>See pick up and demo days in the ordering section</li>
						</ul>
						<br />
						<big>Small branch/log kits – coming soon</big>
						<br /> We are planning to make kits with inoculated branches
						<br />
						Availability and pricing will be posted in spring 2025
					</p>
				</div>
			</div>

			<div
				style={{
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>

			<div
				style={{
					margin: "30px 10px 30px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>
			<div style={{ height: "50px" }}></div>
		</div>
	);
};

export default KitsAvailability;
