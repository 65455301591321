import React, { useState, useEffect } from "react";
import { onReadMore } from "./Functions";

import bigbag from "./images/SAWDUSTSPAWN1.gif";

const Spawn = () => {
	const [tempa, setTempa] = useState("");

	useEffect(() => {
		const font = document.getElementById("es-position");
		setTempa(font.style.fontFamily);
	}, []);

	return (
		<div id="es-position" className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="availability-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "180px",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Availability</h2>
					<p id="p" align="justify">
						Spawn is available as bulk sawdust spawn in bags. Inoculating the
						sawdust spawn into hardwood logs will require a drill and a bit, an
						inoculating tool, and the holes will need to be sealed to prevent
						the spawn from drying out and killing the mycelium. See a precis of
						the log inoculation process and see about Shiitake Log Production &
						Management. You can only use FRESH LOGS, or freshly cut stumps. Old
						logs that have been laying in the bush for longer than six months
						WILL NOT work. They are either too dried out or contaminated with
						other fungi. The best success is achieved with fresh winter cut logs
						– cut as the leaves are falling or later, and before the leaves come
						– before leaf bud. This will ensure the highest concentrations of
						vitamins and nutrients in the sap wood for the mycelium. Inoculation
						should take place in the early spring when the temperature is above
						10 C or 55 F. There is no point in inoculating at much lower
						temperatures because the spawn will not be very active. Moreover
						fresh mycelium can be adversely affected by very low
						temperatures/below freezing. Ideally inoculation should be finished
						before temperatures rise above 18 C/70 F as higher temperatures can
						lead to more contamination. If your logs are cut in late fall or
						winter, inoculation can take place immediately in the spring. Logs
						can be cut until the sap begins to run – best if they are cut before
						mid-March. If however they are cut just before leaf out, it is
						advisable to leave the logs for several weeks or a month so that
						secondary, anti-fungal substances produced by the living log can
						break down; these substances can inhibit the mycelium. Be careful
						however not to dry out the logs. Do not leave them fully exposed to
						the sun, especially for long periods of time/hours in the late
						afternoon. The ideal moisture content should be close to 40%. If you
						think the logs are too dry you can soak, or sprinkle them with water
						for 24 hours several days prior to inoculation.
					</p>
				</div>
			</div>
			<div
				id="availability"
				style={{
					position: "relative",
					bottom: "00px",
					float: "right",
					right: "10px",
					width: "90px",
					height: "fit-content",
					background: "red",
					cursor: "pointer",
					borderRadius: "5px",
				}}
				onClick={(e) => onReadMore(e, 180)}
			>
				Read more
			</div>

			<div
				style={{
					top: "40px",

					position: "relative",
					background: "#474747",
					height: "fit-content",
					textAlign: "left",
				}}
			>
				<p>&nbsp;</p>
				<h2 style={{ textAlign: "center" }}>Important</h2>
				<ul>
					<li>
						<p>Sawdust spawn is made to order and must be prepaid</p>
					</li>
					<li>
						<p>
							We prepare spawn in March and deliver from April to June &#8211;
							just in time for outdoor hardwood log inoculation season; we do
							not make spawn year-round
						</p>
					</li>

					<li>
						<p>Orders should be placed by March 15</p>
					</li>
					<li>
						<p>
							Small orders will be taken until the end of April and will depend
							on availability
						</p>
					</li>
					<li>
						<p>
							Logs should be cut in the winter for spring inoculation and kept
							in appropriate setting
						</p>
					</li>
					<li>
						<p>
							Do research during winter months and cut logs in winter ready for
							&#8216;just in time&#8217; spawn delivery in spring
						</p>
					</li>
					<li>
						<p>
							See our spawn pages (links are above) and our referenceslinks page
							s for information about spawn use
						</p>
					</li>
				</ul>
				<hr />
				<p align="justify" style={{ margin: "10px" }}>
					Each 2.5 kilo bag of spawn will inoculate 20 to 25 logs that are 4
					feet long, depending on the diameter of the log. Larger diameter logs
					can take more spawn and thinner logs take less. More spawn can be used
					around knots and wounds.
				</p>
				<p>&nbsp;</p>
			</div>
			<div
				style={{
					position: "relative",
					top: "50px",
					background: "#2D2D2D",
					height: "400px",
				}}
			>
				<h2 style={{ textAlign: "center" }}>Pricing</h2>
				<div style={{ width: "65%", margin: "0 auto" }}>
					<div style={{ textAlign: "left" }}>
						LOOSE SAWDUST SPAWN Price / 2.5 kilo Bag
					</div>
					<div style={{ textAlign: "left" }}>
						Shiitake (Lentinula edodes) (wide temperature range strain){" "}
					</div>
					<div style={{ textAlign: "left" }}>
						Oyster (Pleurotus ostreatus, variety – Grey Dove)
					</div>
					<div style={{ textAlign: "left" }}>
						Lion’s Mane (Hericium erinaceous)
					</div>
				</div>
				<div style={{ width: "70%", margin: "20px" }}></div>
				<div className="grid-container">
					<div className="item1">
						1 regular bag (2.5 kg) $ 45. Enough to inoculate 20 to 25 logs
						depending on log diameter and inoculation rate
					</div>
					<div className="item2">
						<img src={bigbag} alt="" />
					</div>
					<div className="item3">
						1 small bag (1.75 kg) $ 30. Enough to inoculate 10 to 15 logs
						depending on log diameter and inoculation rate
					</div>
				</div>
				<div style={{ marginTop: "130px", height: "250px" }}>
					<div
						style={{
							width: "80%",
							margin: "0 auto",
							display: "grid",
							gridTemplateColumns: "0.6fr 2fr 0.8fr",
							gridTemplateRows: "30px 30px 30px 30px 30px 30px 30px",
							gridTemplateAreas: `"1, 2, 3", "4, 5, 6", "7, 8, 9, 10"`,
						}}
					>
						<div>2-4</div>
						<div>Regular2.5kg bags</div>
						<div>$40/bag</div>
						<div>5-7</div>
						<div>Regular2.5kg bags</div>
						<div>$35/bag</div>
						<div>8-15</div>
						<div>Regular2.5kg bags</div>
						<div>$31/bag</div>
						<div>16-30</div>
						<div>Regular2.5kg bags</div>
						<div>$27/bag</div>
						<div>31-49</div>
						<div>Regular2.5kg bags</div>
						<div>$23/bag</div>
						<div>50-99</div>
						<div>Regular2.5kg bags</div>
						<div>$20/bag</div>
						<div>100-+</div>
						<div>Regular2.5kg bags</div>
						<div>$15/bag</div>
					</div>
				</div>
			</div>
			<div id="space20" style={{ height: "20px" }}></div>
		</div>
	);
};
export default Spawn;
