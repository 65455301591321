import React from "react";
import { onReadMore } from "./Functions";
import logs_end_closeup from "./images/logs_end_closeup.png";
import Logs_standing_A_frame from "./images/Logs_standing_A_frame.png";
import long_standing_trees from "./images/long_standing_trees.jpg";
import gomba from "./images/gomba.png";
import debarked_trees from "./images/debarked_trees.png";
import treebark_closeup from "./images/treebark_closeup.jpg";
import gomba_oldalt from "./images/gomba_oldalt.png";
import gomba_ontree from "./images/gomba_ontree.png";

const CultivationOyster = () => {
	return (
		<div className="es-position">
			<div
				style={{
					padding: "0 20px 0",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<h4 style={{ textAlign: "center" }}>
					LOG MANAGEMENT FOLLOWING SPAWN RUN / INCUBATION
				</h4>
				<p style={{ textAlign: "Justify" }}>
					Following the spawn run you might see white mycelium ‘show’ at the end
					of the log in the sapwood as in the picture below. This is generally
					the following spring. At this point inoculated logs will be ready to
					‘pin’ and then to ‘fruit’ – to produce mushrooms. Logs must be moved
					from the stack and stood up at this point. The logs should be located
					in a well-ventilated area, preferably on a south-facing slope under
					the forest canopy. Do not locate logs in standing water such as a
					swamp.
					<br />
					<br />
					<img width="200px" src={logs_end_closeup} alt="logs_end_closeup" />
					<br />
					<i>
						Mycelium showing in sapwood at ends of logs after the spawn run /
						incubation phase
					</i>{" "}
					<br />
					<br />
					With proper handling and good management, the logs should give you 4
					to 5 years of production depending on the hardness of the wood, the
					diameter of the logs, and the integrity of the bark. Rule of thumb is
					that the logs will last for a year for each inch of diameter. The logs
					will produce mushrooms outdoors from May to November and can be
					fruited in a greenhouse during the winter months or left outside in
					dormant mode for the winter. There are various ways to stand logs:
					leaning around standing trees in ‘tepee’ formations; leaning in ‘A
					frame pattern’ along barbed wire strung between poles as in the
					picture below; or leaning on split rails – two rails approximately 3
					feet high facing each other about four feet apart. This latter
					arrangement allows you to flip logs from rail to rail as you harvest,
					and it is low profile, so logs are protected from wind. You can leave
					the logs in this arrangement over the following winter after harvest
					season. Note that standing logs should be moved to a low-profile stack
					or split rail arrangement for the winter. Then stand them up again the
					following spring. See this document for pictures and drawings of
					various stacking patterns. “Best Management Practices for Log-Based
					Shiitake Cultivation in the NE USA” from Cornell University
					<br />
					<br />
					<img src={Logs_standing_A_frame} alt="Logs_standing_A_frame" />
					<br />
					<i>
						Logs standing A-frame style on barbed wire strung between trees;
					</i>
					<br />
					<br />
					<ul>
						<li> alternate logs are on opposite sides of the wire; </li>
						<li>
							position split rails on each side of the barbed wire (between
							rows) to allow ‘flipping’ logs to the rail on that side of the
							wire;
						</li>
						<li>split rails should be about 3’ high;</li>
						<li>
							logs can stay on the lower profile split rails for the winter{" "}
						</li>
					</ul>
					<br />
					<br />
					<img
						width="500px"
						src={long_standing_trees}
						alt="long_standing_trees"
					/>
					<br />
					<i>
						Logs standing ‘tepee style’ leaning on living trees and some leaning
						on barbed wire strung between trees
					</i>
					<br />
					<br />
					The environmental factors that provoke ‘fruiting’ include temperature,
					length of daylight, moisture, and ‘trauma’/physical impact. Some
					growers have found that moving logs, dropping them on the ground, and
					even hitting logs with a hammer will instigate pinning when the other
					conditions are present. Moving/dropping/hitting logs following spring
					rains when ambient temperatures start to climb can induce fruiting.
					Shiitake mushrooms should be harvested when the cap is fully formed
					and is pulling away from the stalk. Oyster mushrooms should be
					harvested when the clump is fully formed and before the cap starts to
					flatten. Lion’s Mane are harvested when the ‘head’ is formed and still
					dense. All mushrooms should be harvested when they are dry, so it is
					advisable to harvest before rainfall or to protect developing
					mushrooms from rain. Wet mushrooms rot more quickly.
				</p>
			</div>

			<div
				style={{
					background: "#474747",
					height: "fit-content",
				}}
			>
				<div
					id="tree-oyster-mushroom-container"
					style={{
						position: "relative",
						background: "#474747",
						height: "fit-content",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Potential problems:</h2>
					<p id="p" align="justify" style={{ padding: "0 20px 0" }}>
						It is important to remember that the mycelium is adversely affected
						by lack of moisture inside the log and/or high temperature. The logs
						must be prevented from drying out inside and from overheating. This
						can be achieved by keeping them under the forest canopy and watering
						when necessary. Shade can be natural, as under trees or bushes, or
						man-made, as under a porch or breathable shade cloth. The logs
						should not be exposed to hours of direct sunlight, especially in the
						later afternoon; breathable shade cloth, burlap, or even cedar
						boughs can be sued to protect logs from direct sunlight. Dehydration
						can be avoided by exposure to natural rainfall or by manually
						watering – soaking or sprinkling. During a drought you may have to
						water the logs on a regular basis to prevent them from drying out.
						However, sprinkling logs for short intervals on a regular basis
						(like a garden) will result in de-barking and will shorten the life
						of the log. The integrity of the bark must be maintained because it
						is the ‘skin’ of the log -- it keeps moisture in and other organisms
						out. The bark is negatively affected by UV rays and by constant
						wet/dampness.
						<br />
						<br />
						<img src={gomba} alt="gomba" />
						<br />
						<i> </i>
						<br />
						Another potential problem is slugs. Monitor for slugs – especially
						near the ground. Slug control tactics involve:
						<ul>
							<li>Diatomaceous earth on the ground around the logs</li>
							<li> Shallow containers of beer positioned around logs</li>
							<li>Ducks (and perhaps also chickens) will eat slugs</li>
							<br />
						</ul>
						<p style={{ textAlign: "left" }}>
							Another potential problem is debarking – handle logs to minimize
							damage to the bark
						</p>
						<img width="400px" src={debarked_trees} alt="debarked_trees" />
						<br />
						<i>
							Debarked logs; to be culled and disposed - decompose in the
							forest; or dry & burn as debris
						</i>
						<br />
						<br />A natural fruiting will generally occur in the early spring
						following an intensive rainfall event. To get predictable mushroom
						harvests the logs must be ‘forced’. Logs can be ‘forced’ to fruit at
						8-week intervals by soaking or sprinkling for 24 to 48 hours
						(diameter and age of log determines soaking/sprinkling time; older
						more porous logs and thinner logs require shorter soaking/sprinkling
						times). The logs can also be physically “shocked”. This can be
						achieved either by movement, or by dropping them to the ground as
						you harvest. Some growers even hit the logs on the end with a hammer
						to induce fruiting. Large commercial growers control fruiting to
						avoid having all logs producing at the same time; they cover some of
						their logs to protect from rainfall and sprinkle some in batches.
						Logs can also be put into metal racks and moved by tractor to
						soaking tanks, but this is problematic as the logs will float; to
						prevent floating the logs have to be put into the tank in racks
						prior to filling with water. Small growers can achieve this by
						forcing several logs at a time; cover some logs to protect from
						rainfall and sprinkle/soak several logs at a time. Another option is
						to stand them in clean garbage cans or tanks filled with water; logs
						must be weighted with a brick or stone so they won’t float. If using
						municipal chlorinated water you can fill the can and let it stand
						for 24 hours before putting the log/s in but this is difficult
						because the log will pop up/float. Soaking logs in a pond can be a
						problem since the logs will float and may not soak through.
						Sprinkling is the best option. NOTE: We advise cultivators to wait
						until one ‘natural’ fruiting has occurred before ‘forcing’ the logs
						to fruit.
						<br />
						<br />
						<img width="400px" src={treebark_closeup} alt="treebark_closeup" />
						<br />
						<i>
							Debarked logs; to be culled and disposed - decompose in the
							forest; or dry & burn as debris
						</i>
						<br />
						<br />
						Approximately a week after soaking or irrigation the logs will start
						to pin. From the time you see little mushroom ‘pins’ coming out, to
						maturity, depends on the ambient temperature and humidity. Spring
						and fall growth is usually slow but summer mushrooms can mature in a
						couple of days. The mushrooms should be harvested shortly after the
						veil has broken under the cap revealing the gills. Following
						harvesting the logs should be left alone so the mycelium can
						re-energize itself by digesting more wood lignan. This means six to
						eight weeks rest and then the soaking/sprinkling can be repeated.
						Using this management regime the logs can produce 3 to 4 harvests
						per season yielding up to a total of 1 Kilogram of mushrooms per
						year. Less frequent soakings, which allows time for the mycelium to
						store more nutrients between harvests, will generally produce larger
						mushrooms. Harvest Shiitake when cap is well formed and gills have
						separated from stalk. Harvest Oysters when clumps are well formed
						and caps have not flattened. Harvest Lion’s Mane when clumps are
						well formed and quite firm.
						<br />
						<br />
						<div
							style={{
								display: "flex",
								justifyContent: "left",
								alignItems: "flex-end",
							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-end",
								}}
							>
								<img width="200px" src={gomba_ontree} alt="gomba_ontree" />
							</div>
							<div style={{ width: "100px" }}></div>
							<div>
								<img width="200px" src={gomba_oldalt} alt="gomba_oldalt" />
							</div>
						</div>
						<i>Flush of Shiitake musrhooms ready to harvest</i>
						<br />
						<br />
						Harvest when mushrooms are dry – time harvests according to mushroom
						maturity and to avoid rainfall; allow soaked mushrooms to dry on the
						log before harvesting if possible; wet mushrooms are best used
						quickly to make stock. See recipes in information tab.
						<br />
						<br />
						With proper care the logs will last an average of five years – 1
						year for each inch of diameter.
						<h2 style={{ textAlign: "center" }}>Sources of information</h2>
						<p style={{ textAlign: "Justify" }}>
							<i>Growing Gourmet and Medicinal Mushrooms</i> by Paul Stamets
							provides comprehensive details including natural habitat and
							growing parameters. This book can be found online/open source and
							there is a chapter on each of Shiitake, Oyster, and Lion’s Mane.
							Another excellent source for detailed information about mushroom
							cultivation outdoors on hardwood logs is Cornell University in
							association with the Sustainable Agriculture Research and
							Education program and the University of Vermont Extension program.
							These institutions have carried out a Shiitake cultivation project
							and have published results on the SARE.org website. These
							documents have diagrams of staking patterns as well as species of
							trees to use for Shiitake, when to cut logs, how to manage logs,
							and mush more. The techniques are applicable to Oyster and Lion’s
							Mane mushrooms with adjustments relating to temperature and
							humidity. You can find a 56 page manual titled “Best Management
							Practices for Log-based Shiitake Cultivation in the Northeastern
							United States” (browse and download for free). The document can be
							found at the following link. &nbsp;
							<a href="https://projects.sare.org/wp-content/uploads/977907shiitake-bmp-3-11-final-for-print-hires.pdf">
								(sare.org)
							</a>
							<br />
							<br />
							Cornell CALS (College of Agriculture and Life Sciences) has a
							Resource Library Check out the Cornell University Sustainable
							Agriculture Research and Education (SARE.org) website An
							information booklet published by SARE.org can be found at the
							following links. Titled “Log Based / Forest Shiitake Mushroom
							Cultivation, Introductory Grower’s Guide”. It has diagrams for
							stacking patterns – pictures and drawings.
							<br />
							<br />
							<a href="https://projects.sare.org/wp-content/uploads/944524log-base-production.pdf">
								Booklet.pub (sare.org)
							</a>
						</p>
						<br />
						<br />
						<br />
						<br />
					</p>
				</div>
			</div>
		</div>
	);
};
export default CultivationOyster;
