import mycelium from "./images/mycelium.png";
import mush001 from "./images/mush001.jpg";
import mush002 from "./images/mush002.png";

const ShiitakeKit = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="cultivation-techniques-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
						width: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>
						Shiitake Mushroom Kit Instructions
					</h2>
					<h4>(Lentinula edodes, wide temp range strain)</h4>

					<p id="p" align="justify">
						The Shiitake mushroom kit you have received is made up of hardwood
						chips, sawdust, organic wheat bran and a small quantity of crushed
						limestone. It is totally chemical and additive free. The block is
						now probably totally white from the mycelium that has grown through
						the chips/sawdust mix. It will naturally turn brown on the outside
						to form a “skin” as it matures. This mycelium is your mushroom
						organism and with the proper care and stimuli it will produce
						"fruits" or mushrooms. The kit includes a perforated plastic bag and
						four wooden dowel sticks. These are to be used to set the kit up for
						production. To provide the stimuli necessary for mushroom “fruiting”
						follow these steps:
						<ol>
							<li>
								This kit is ready to “fruit”. Remove from the plastic bag and
								set it up in the “tent” as pictured below and begin misting. It
								must be kept in a high humidity environment (75-85% RH). This
								can be achieved by using the perforated bag and the wooden
								dowels as per the drawing. Remove the block from the original
								plastic bag and insert the dowels into the top in an arrangement
								that will hold the perforated bag in a tent-like position over
								the block –inset dowels 1-2 inches into the top of the block,
								one in the middle and the other 3 around the top perimeter at a
								slight angle out. It is important to arrange the dowels so that
								the bag does not touch the block. To keep the humidity high mist
								the block with water at least a couple of times per day. Place
								the kit on a tray or pie plate large enough to catch moisture
								that will condense on the inside of the bag and drip down. The
								block of mycelium needs to “breathe”; the perforations in the
								bag will allow an exchange of gases. You can further facilitate
								gas exchange by gently patting the outside of the bag to force
								out CO2 laden air, and/or by lifting the bag off the block. The
								mushrooms require indirect light, but not direct sunlight. A
								shady corner, or a florescent light will provide adequate light.
								The light regime should be diurnal -- day/night. The optimum
								temperature range is 18-23 °C (50-75°F). Use judgement to
								maintain the optimal conditions of light, moisture, and air by
								mimicking the conditions of a forest floor – shaded, moist,
								fresh.
							</li>
							<br />
							<li>
								About a week later small bumps should start to form on the
								block. These will become mushrooms and once they start to form
								they should not be wet or they will rot. At this point it is
								advisable to direct the mist around the inside of the bag rather
								than directly onto the kit/mushrooms. The aim is to create a
								high humidity environment while avoiding wet/soaked mushrooms.
								Attention to the air quality under the tent is important – vent
								as necessary to force out CO2. Continue with this misting
								procedure until the mushrooms are about 3 inches in diameter. At
								this stage of mushroom development alter the misting routine in
								order to create a less humid environment -- mist less often to
								produce the best quality mushrooms. Pick your mushrooms when
								they are 3 to 6 inches in diameter but before the cap flares
								out. Before harvesting remove the tent to allow the mushrooms to
								dry somewhat.
							</li>
							<br />
							<li>
								After the first crop replace the block in its tent and let it
								rest for about 1 week. This resting period gives the mycelium a
								chance to digest more wood and build up its energy for the next
								"flush".{" "}
								<b>
									<u>Mist lightly during this resting period.</u>
								</b>
							</li>
							<br />
							<li>
								In order to initiate another flush of mushrooms following the
								initial fruiting the kit must be soaked overnight in cold water
								(avoid chlorinated water or allow it to stand for a few days to
								dissipate the chlorine). You can do this by placing the kit in
								the sink or a bucket full of water and weighting it down
								(otherwise it will float). Fruiting is further encouraged by
								“shocking” -- carefully thump the soaked block on a hard surface
								several times without letting it break apart.{" "}
							</li>
							<br />
							<li>
								Repeat the above cycle – resting, soaking/shocking, fruiting,
								harvesting -- until all the nutrients embodied in the block have
								been used up and no more mushrooms form.{" "}
								<b>
									<u>
										Check your kit regularly for green mold. If you notice an
										increasing amount of green mold, moderate the spraying
										regime as it is likely that the micro-environment is too
										humid. If mold has overgrown the outside of the block, you
										can wash it under running water.
									</u>
								</b>
							</li>
							<br />
							<li>
								After the kit is finished you can break it up and apply on
								garden beds or houseplants. It makes an excellent soil
								conditioner that increases water retention and nutrients for
								soil organisms.
							</li>
						</ol>
					</p>
					<p id="p" align="left">
						<br />
						<img src={mycelium} alt="" width="500px" />
						<br />
						<br />
						<br />
						<img src={mush001} alt="" width="500px" />
						<br />
						Fruiting kit – time to harvest the larger clump of mushrooms by
						cutting/slicing it off the sawdust kit
						<br />
						<br />
						<br />
						<img src={mush002} alt="" width="500px" />
						<br />
						Time to harvest the larger mushroom – carefully cut it off and leave
						the smaller mushroom until it matures
						<br />
						<br />
					</p>
					<p id="p" align="left">
						Contact us by telephone at 416 963 5520 or at office@mycosource.com{" "}
						<br />
						Search our website: www.mycosource.com for more information on
						mushroom cultivation.
					</p>
				</div>
			</div>

			<div
				style={{
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="indoor-cultivation-container"
					style={{
						position: "relative",
						background: "#474747",
						height: "fit-content",
						overflow: "hidden",
						width: "100%",
					}}
				></div>
			</div>

			<div
				style={{
					margin: "30px 10px 30px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>
			<div style={{ height: "50px" }}></div>
		</div>
	);
};

export default ShiitakeKit;
