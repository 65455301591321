import React from "react";

const Functions = () => {
	return <div>Functions</div>;
};

export function onReadMore(e, lenght) {
	const thisContainer = document.getElementById(e.target.id + "-container");
	const moreOrLess = thisContainer.getBoundingClientRect().height === lenght;
	const spawnReadMore = document.getElementById(e.target.id);
	thisContainer.style.height = moreOrLess ? "fit-content" : +lenght + "px";
	spawnReadMore.innerHTML = moreOrLess ? "Read Less" : "Read More";
}

export default Functions;
