import React from "react";
import { onReadMore } from "./Functions";
import thumbtool from "./images/thumbtool-300x115.jpg";
import palmtool from "./images/palmtool-300x118.jpg";
import repairkit from "./images/repairkit-300x144.gif";
import drillbit from "./images/drillbit-300x110.jpg";
import collar from "./images/collar-300x147.jpg";
import adapter from "./images/Adapter-300x116.jpg";
import preassembled from "./images/preassembled-300x105.jpg";
import styrofoam from "./images/styrofoam-300x123.jpg";
import dobbers from "./images/dobbers-300x141.jpg";

import "./Equipment.css";

const Equipment = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="manual-inoc-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Manual Inoculation Tools</h2>
					<p id="space20" align="justify">
						These tools are made with a hollow brass tube that you insert into
						sawdust spawn to fill the tube and then plunge into inoculation
						holes. Made with a molded wooden grip. Replacement spring & tip sets
						available for both tools; Palm tool spring is heavier and tighter
						and is designed to be used with both hands. Made in USA bout
						Kombucha on the internet at www.kombucha.com and other sites.
					</p>
				</div>
			</div>

			<br />
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#474747",
					height: "fit-content",
				}}
			>
				<div
					id="equipment-container"
					style={{
						position: "relative",
						background: "#474747",
						height: "1750px",
					}}
				>
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={thumbtool} />
						</div>
						<div class="right-div">
							<div class="text-sub-div">
								Thumb Inoculator Use with one hand grip and plunge/press with
								your thumb; solid brass; durable
							</div>
							<div class="price-sub-div">$60.00</div>
						</div>
					</div>
					<br />
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={palmtool} />
						</div>
						<div class="right-div">
							<div class="text-sub-div">
								Palm Inoculator Hold with one hand & plunge with palm of the
								other hand; tighter spring; solid brass; durable
							</div>
							<div class="price-sub-div">$65.00</div>
						</div>
					</div>
					<br />
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={repairkit} />
						</div>
						<div class="right-div">
							<div id="repairkit" class="text-sub-div">
								Palm Inoculator Hold with one hand & plunge with palm of the
								other hand; tighter spring; solid brass; durable
							</div>
							<div class="price-sub-div">$12.00</div>
						</div>
					</div>
					<br />
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={drillbit} />
						</div>
						<div class="right-div">
							<div class="text-sub-div">
								Auger bits with integrated depth stop. Recommended; made
								specifically for log-based mushroom cultivation (to leave a
								clean hole at the correct depth). Makes drilling faster and
								easier.
							</div>
							<div class="price-sub-div"></div>
						</div>
					</div>{" "}
					<p align="justify">
						Bits without an integrated depth stop can be fitted with a collar 2
						sizes – larger size allows you to put more spawn in each hole; the
						larger bit is used with the sawdust spawn plugs (coming next year)
						<p>
							12.5 mm use with thumb and palm style manual inoculators $28.50
						</p>
						<p>
							13.5 mm use with thumb and palm style inoculators (and with
							sawdust plugs coming next year). Puts more spawn into hole $30.50
						</p>{" "}
						Bits can be sharpened with a diamond or carborundum file. The kit we
						offer can be mounted on a grommet tool
					</p>
					<br />
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={collar} />
						</div>
						<div class="right-div">
							<div id="repairkit" class="text-sub-div">
								<b>Drill Bit + depth stop collar</b> These bits are steel and
								are also auger bits specially made for log-based cultivation but
								w/o the depth stop. comes with a depth stop collar to be
								attached
							</div>
							<div class="price-sub-div">$20.00</div>
						</div>
					</div>
					<p id="space20" align="justify"></p>
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={adapter} />
						</div>
						<div class="right-div">
							<div id="repairkit" class="text-sub-div">
								<b>Angle Grinder Adaptors Adapter</b> Adaptors allow you to
								mount your bit on an angle grinder and use it as a modified
								high-speed drill. The adapters fit onto your grinder ‘spindle’
								(tighten with ‘allen key’).
							</div>
							<div class="price-sub-div">$45.00</div>
						</div>
					</div>
					<div align="justify">
						The drill bit fits on the other end and tightens with the set screw
						– bit spindle has a flat, textured face for a secure tight fit. Both
						12.5 mm and a 13.5 mm bits can be used with the adapter. Angle
						grinders generally run at 10,000 rpm and will make drilling your
						logs much faster and easier; like using a high-speed drill. Angle
						grinders are moderately priced and easily available. Adaptors fit a
						5/8″ diameter spindle — generally 41/2″ long and 11 threads per
						inch. They have been designed to fit most makes and models of angle
						grinders currently available FYI: 12mm = .4724 inches and 13.5 mm =
						.5118 inches
					</div>
					<p id="space20" align="justify"></p>
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={preassembled} />
						</div>
						<div class="right-div">
							<div id="repairkit" class="text-sub-div">
								<b>Adaptor + bit</b> w/depth stop pre-assembled (12.5mm bit
								only)
							</div>
							<div class="price-sub-div">$68.00</div>
						</div>
					</div>
					<p id="space20" align="justify"></p>
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={styrofoam} />
						</div>
						<div class="right-div">
							<div id="repairkit" class="text-sub-div">
								Styrofoam caps – to seal inoculation holes Available in two
								sizes – 12.5mm and 13.5mm to match bit size $0.01/cap Caps come
								in rolls and you simply pinch off each cap and insert into hole
								on top of the spawn; cap should be flush with the bark
							</div>
							<div class="price-sub-div">$0.01/cap</div>
						</div>
					</div>
					<p id="space20" align="justify"></p>
					<div align="justify">
						Order caps based on size of drill bit and on the number of logs you
						are inoculating based on average of 50 inoculation holes per log
						(smaller logs require fewer holes and less spawn per hole)
					</div>
					<p id="space20" align="justify"></p>
					<div class="main-container">
						<div class="left-div">
							<img alt="" src={dobbers} />
						</div>
						<div class="right-div">
							<div id="repairkit" class="text-sub-div">
								<b>Wax dobbers</b> dip into melted wax and dob to seal the
								holes; reusable
							</div>
							<div class="price-sub-div">$0.50/ea</div>
						</div>
					</div>
					<p id="space20" align="justify"></p>
					<br />
				</div>
			</div>
		</div>
	);
};

export default Equipment;
