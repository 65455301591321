// Products.js
import React from "react";
import { onReadMore } from "./Functions";

import PinkOyster300 from "./images/Pink-Oyster-300x200.jpg";
import PerlOyster300 from "./images/Pearl-Oyster-300x200.jpg";
import FruitingLog from "./images/Fruiting-log-closeup1-300x200.jpg";

function Products() {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="books-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "180px",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Books</h2>
					<p id="p" align="justify">
						We no longer carry books because you can order via Amazon.com and
						other internet sites. There are many excellent wild mushroom
						identification guides available as well as books about Kombucha and
						medicinal mushrooms. For mushroom cultivation outdoors on hardwood
						logs we recommend “The Shiitake Growers Handbook” by Przybylowicz
						and Donoghue as well as “Growing Shiitake in a Continental Climate”
						by Krawcyk and Kozak (currently out of print). “Growing Gourmet and
						Medicinal Mushrooms” by Paul Stamets contains chapters detailing
						indoor and outdoor cultivation on both Shiitake and Oyster. There
						are internet sites with quite a bit of information on mushroom
						cultivation. For information about kombucha we recommend “Kombucha:
						healthy beverage from the Far East” by Gunther Frank as well as
						“Kombucha Rediscovered!” by Klaus Kaufmann (Alive Books). You will
						find comprehensive information about Kombucha on the internet at
						www.kombucha.com and other sites.
					</p>
				</div>
			</div>
			<div
				id="books"
				style={{
					position: "relative",
					bottom: "15px",
					float: "right",
					right: "10px",
					width: "90px",
					height: "fit-content",
					background: "red",
					cursor: "pointer",
					borderRadius: "5px",
				}}
				onClick={(e) => onReadMore(e, 180)}
			>
				Read more
			</div>

			<div style={{ height: "fit-content" }}>
				<div
					style={{
						top: "40px",
						margin: "10px 10px 20px 10px",
						position: "relative",
						padding: "10px",
						background: "#474747",
						height: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Custom sterilization</h2>
					<div className="most-popular-container">
						<p align="justify">
							We specialize in custom sterilization of bags containing your
							unique substrate recipe, including grain, sawdust, and more.
							Minimum order quantities apply. Please contact us via email at
							office@mycosource.com for inquiries.
						</p>
					</div>
				</div>
			</div>

			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="spawncontainer"
					style={{
						top: "30px",
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Logs</h2>
					<p id="p" align="justify">
						Inoculated logs for your backyard or cottage. See the picture of
						Shiitake below. Please note that we do not provide raw logs for
						inoculation. If you are planning to do an inoculation project you
						must secure your own logs. Logs should be cut after leaf fall in the
						autumn and before leaf bud in the spring; they can ‘rest’ over the
						winter laying off the ground on a skid or other device to keep them
						off the ground, covered with snow. Do not leave exposed logs in full
						sunlight during the winter — cover with burlap or a tarp that can
						‘breathe’.
					</p>
				</div>
			</div>
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="spawncontainer"
					style={{
						position: "relative",
						background: "#474747",
						height: "180px",
						overflow: "hidden",
					}}
				>
					<h2 style={{ textAlign: "center" }}>Spawn</h2>
					<p id="p" align="justify">
						Spawn is the name for the mushroom inoculant which is a culture of
						fungal mycelium grown out on various media such as sawdust. For log
						growers we have bulk sawdust spawn. For sawdust block growers we
						have grain or sawdust spawn.
					</p>
				</div>
			</div>

			<div style={{ height: "fit-content" }}>
				<div
					style={{
						position: "relative",
						padding: "10px",
						background: "#474747",
						height: "100%",
					}}
				>
					<div className="most-popular-container">
						<div style={{ width: "100%" }}>
							<div
								style={{
									display: "flex",
									height: "fit-content",
									width: "100%",
									justifyContent: "center" /* horizontally center */,
									alignItems: "center" /* vertically center */,
								}}
							>
								<div>
									<img alt="" width="100%" src={PinkOyster300} />
								</div>
								<div>
									<img alt="" width="100%" src={PerlOyster300} />
								</div>
								<div>
									<img alt="" width="100%" src={FruitingLog} />
								</div>
							</div>
							<div style={{ marginTop: "10px", textAlign: "justify" }}>
								To ship spawn outside of Continental North America you will
								likely have to obtain a phyto-sanitary certificate from the
								applicable agency in order to import live spawn. This should be
								done before we ship so that your spawn won’t be held at customs.
								Spawn is a living fungus and is subject to deterioration and
								death if held for too long. Ordering a live culture for
								propagation is another option. Importing a live culture may also
								require appropriate certification but may pass through customs
								more easily. Shipping by international courier.
							</div>
						</div>
					</div>

					<div
						id="spawncontainer"
						style={{
							top: "30px",
							position: "relative",
							background: "#2d2d2d",
							height: "fit-content",
							overflow: "hidden",
						}}
					>
						<h2 style={{ textAlign: "center" }}>European customers</h2>
						<p id="p" align="left">
							customers please contact Menno Haveman or Sjoerd”s Shiitake in
							Holland <p>haveman.groen@wxs.nl</p>
							http://www.havemangroen.nl/global/nl/contact
							info@sjoerdsshiitake.nl
						</p>
					</div>
					<div
						id="ordering-procedures"
						style={{
							top: "30px",
							position: "relative",
							background: "#474747",
							height: "fit-content",
							overflow: "hidden",
						}}
					>
						<h2 style={{ textAlign: "center" }}>Ordering Procedures</h2>
						<p id="p" align="justify">
							Prices quoted are in CANADIAN DOLLARS. Orders must be prepaid
							either by check/money order or by interac e-transfer. Shipping is
							extra via Canada Post or UPS. Canadian residents are subject to
							HST on equipment and supplies and on shipping. Please contact us
							by e-mail and we will get back to you with a quote. We will need
							full address information in order to calculate shipping charges.
						</p>
					</div>
					<div style={{ height: "50px" }}></div>
				</div>
			</div>
		</div>
	);
}

export default Products;
