import mycelium from "./images/mycelium.png";
import mush003 from "./images/mush003.png";
import mush004 from "./images/mush004.png";

const OysterKit = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="cultivation-techniques-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
						width: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>
						Oyster Mushroom Kit Instructions{" "}
					</h2>
					<h4 style={{ textAlign: "center" }}>
						(Pleurotus ostreatus, var Grey Dove)
					</h4>
					<p id="p" align="justify">
						The Oyster mushroom kit you have received is made up of hardwood
						sawdust, organic wheat bran and a small quantity of agricultural
						gypsum. It is totally chemical and additive free. The block is now
						probably totally white from the mycelium which has grown through the
						mix. This mycelium is your mushroom organism and with the proper
						care and stimuli it will produce the "fruits" or mushrooms. The kit
						includes a perforated plastic bag and bamboo skewers which will be
						used to set the kit up for production. <br />
						<br />
						To provide the stimuli necessary for mushroom “fruiting” follow
						these steps:
						<br />
						<ol>
							<li>
								Leave the kit in its original bag, undisturbed, at room
								temperature, for 2-3 days to recover from shipping. Cut off the
								top 3 inches of the bag and fold the bag over the block so that
								the filter patch is covered and tape it to the block with clear
								tape. With a sharp knife make several X incisions in the bag
								that the kit is in, approximately 2/3 of the way up the sides.
								The X cuts should be roughly 2.5cm to 4cm/1 to 2” across - large
								enough to allow mushrooms to emerge.
							</li>
							<br />
							<li>
								Small "bumps' (called pins) will start to form on the kit at the
								site of the cuts and mushrooms will emerge through the X cuts in
								the bag. Once the mushrooms start to form, they grow quickly; do
								not mist directly onto the mushrooms - direct the mist around
								the inside of the bag rather than directly onto the mushrooms.
								This will facilitate a high humidity environment while avoiding
								wet/soaked mushrooms. You can also put a clean sponge soaked
								with water inside the tent to maintain humidity. Check the kit
								frequently and harvest mushrooms when clumps are fully formed.
								Cut clumps off carefully to allow any smaller heads or
								individual mushrooms to continue forming from this site.{" "}
							</li>
							<br />
							<li>
								This kit must be kept in a warm, humid environment: optimum
								temperature range is 15-21°C (55-70°F).and optimum humidity is
								65-85%. This can be achieved by using the perforated bag and the
								skewers. Insert the skewers into the block in an arrangement
								that holds the perforated bag in a tent-like position over the
								block. The skewers should be inserted about 1-2 inches into the
								block, two on the top and the other 4 around the top perimeter
								at a slight angle out. Arrange the skewers so that the bag does
								not touch the kit but forms a ‘tent’ over the kit. To keep the
								humidity high you should mist inside the ‘tent’ with water a
								couple of times per day. Place the kit on a tray or pie plate
								large enough to protect the surface of the table or floor
								because water will condense on the inside of the bag and drip
								down. The kit needs to “breathe” and the perforations in the bag
								will allow an exchange of gases; you can open the tent
								periodically to allow oxygen in and carbon dioxide out. The
								mushrooms require some light but not direct sunlight.{" "}
							</li>
							<br />
							<li>
								After the first ‘flush’ the kit can be ‘forced’ again. For a
								second flush of mushrooms, cut 4 new holes in the plastic bag
								that the kit came in – about 1/3 of the way up from the bottom.
								Keep the kit in a humid and warm environment and harvest the
								clumps of mushrooms as they emerge as per step 3. When no more
								mushrooms emerge after about 1 week you can try to force more
								mushrooms to grow: strip off the plastic bag and soak the kit in
								cold water for 1 hour (if using chlorinated water let it stand
								for a few hours to dissipate the chlorine); place the kit in a
								bucket of water with a weight on top to hold it underwater as
								the kit tends to float. After soaking replace the kit in its
								“tent” but flip it over and start the misting procedure over
								again.
							</li>
							<br />
							<li>
								To initiate successive “flushes” you can repeat this procedure:
								allow the kit to rest for a few days and then re-soak it and
								start over as per step 4. You can repeat the above procedure
								several times or until all the nutrients have been used up and
								no more mushrooms form. At this point the kit will shrink and
								begin to dry out and harden and you will see that it is
								depleted.{" "}
							</li>
							<br />
							<li>
								7. After the kit is depleted and no more mushrooms emerge you
								can break it up and use as compost. It makes an excellent soil
								conditioner.
							</li>
						</ol>
						<br />
						Contact us by telephone at 416 963 5520 or at office@mycosource.com
						<br />
						Search our website: www.mycosource.com for more information on
						mushroom cultivation.
					</p>
					<p id="p" align="left">
						<br />
						<img src={mycelium} alt="" width="500px" />
						<br />
						<br />
						<br />
						<img src={mush003} alt="" width="500px" />
						<br />
						Oyster mushrooms forming at cut site on bag; notice moisture inside
						bag of fully colonized sawdust spawn
						<br />
						<br />
						<br />
						<img src={mush004} alt="" width="500px" />
						<br />
						Clump of Oyster mushrooms ready to harvest – careful! Delicate.
						<br />
						Cook and eat immediately or store in cardboard box refrigerated.
						<br />
						<br />
					</p>
				</div>
			</div>

			<div
				style={{
					margin: "30px 10px 30px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>
			<div style={{ height: "50px" }}></div>
		</div>
	);
};

export default OysterKit;
