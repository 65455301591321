import React from "react";

function Blank() {
	return (
		<div className="es-position">
			<div style={{ background: "#2D2D2D", height: "fit-content" }}>
				<div style={{ background: "#2D2D2D", height: "fit-content" }}>
					<h2 style={{ textAlign: "center" }}>Welcome to Mycosource Inc</h2>
					<p align="center">
						Mycosource Inc. is a Southern Ontario company specializing in
						outdoor log inoculation for cultivating Shiitake, Oyster,
						<br />
						and Lion's Mane mushrooms on hardwood logs in North America.
						<br /> <br />
					</p>
				</div>
			</div>
			<div style={{ height: "fit-content" }}></div>
			<div style={{ background: "#2D2D2D", height: "fit-content" }}></div>
			<div id="space20" style={{ height: "20px" }}></div>
		</div>
	);
}

export default Blank;
