import mycelium from "./images/mycelium.png";

const LionsKit = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="cultivation-techniques-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
						width: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>
						Lion’s Mane Mushroom Kit Instructions
					</h2>
					<h4> (Hericium erinaceum)</h4>
					<p id="p" align="justify">
						The Lion’s Mane mushroom kit you have received is made up of
						hardwood sawdust, organic wheat bran and a small quantity of
						agricultural gypsum. It is chemical and additive free. The sawdust
						kit is now probably mostly white from the mycelium which has grown
						through the substrate. This mycelium is your mushroom organism and
						with the proper care and stimuli it will produce the "fruits" or
						mushrooms. The kit includes a plastic bag to serve as a ‘tent’ or
						‘humidity dome’ (if it is not perforated you can cut holes in the
						bag) and bamboo skewers which will be used to set the kit up for
						production. Set-up instructions below.{" "}
					</p>
					<p id="p" align="justify">
						When you receive the kit leave it in the original bag, undisturbed,
						at room temperature (15 – 20C) for 24 hours to recover from
						shipping. Lion’s Mane prefers a cool, humid environment: The
						temperature, humidity, and oxygen exchange requirements vary
						throughout the development process – first is the development of
						primordia and then development of fruitbodies (mushroom heads) see
						the growing parameters below. Timelines can vary and slight
						adjustments in conditions can make a difference. When you see small
						bumps on the surface of the kit this indicates the kit is beginning
						to form primordia. Lion’s Mane demands attention to detail –
						temperature and humidity as well as O2 exchange requirements.
						Attention to temperature and humidity and air exchange is
						recommended. Handle carefully. Locate the kit appropriately as
						described below.{" "}
					</p>
					<p id="p" align="justify">
						Step 1 – prepare the kit to fruit: cut off the top corners of the
						bag and roll it down to squeeze out the air; fold several times and
						wrap tightly over the block so that the gas exchange filter patch is
						covered; tape it tightly to the block with clear tape. This should
						be a tight fit.{" "}
					</p>
					<p id="p" align="justify">
						With a sharp knife make a 2 to 3” cut in the bag near the top and
						another small opening in the bag 1/3 of the way up from the bottom.
						You will see primordia forming on the surface of the kit where it
						has access to oxygen – where you cut it/punctured it.
					</p>
					<p id="p" align="justify">
						It is important to note that at this point after squeezing out all
						air and taping the bag tightly over the kit you want to cut/puncture
						the bag in one or two spots so that a fruitbody head will form at
						these sites. The aim is to favour mushroom development only at these
						sites to get larger heads and to inhibit other primordia from
						forming into mushrooms. This will encourage development of several
						large mushrooms rather than many smaller heads.{" "}
					</p>
					<p id="p" align="justify">
						Place the kit in a high humidity enclosure / tent-like structure. To
						make a ‘tent-like structure’ insert 2 or 3 wooden skewers around the
						top perimeter of the kit at 45% angels in an arrangement that holds
						the perforated bag in a tent-like position over the block so that
						the bag does not touch the kit but forms a ‘tent’ or ‘dome’ over the
						kit.
					</p>
					<p id="p" align="justify">
						Place the kit into the tent at{" "}
						<b>50-60F or 10 – 15.6C with 95-100% humidity</b> (cool and humid
						with ambient daylight but not direct sunlight) and allow 3-5 days or
						perhaps longer for the primordia to develop. <b>Primordia</b> are
						the small bumps/eruptions that you will see on the surface of the
						kit that will develop into <b>mushroom fruitbodies.</b> Notice that
						this is a cool temperature so locate the kit & tent appropriately;
						you can move to a slightly warmer spot when the fruitbody/mushroom
						begins to form and emerge from the bag.{" "}
						<i>
							Locate it where you will check it frequently to mist and to
							‘billow’ the dome to allow air exchange.
						</i>
					</p>
					<p id="p" align="justify">
						To keep the humidity high mist inside the ‘tent’ with water a couple
						of times per day. Place the kit on a tray or pie plate large enough
						to protect the surface of the table or floor because water will
						condense on the inside of the bag and drip down. The kit needs to
						“breathe” and the perforations in the bag will allow an exchange of
						gases; you should billow the tent a few times a day at least; you
						might even open the tent periodically to allow oxygen in and carbon
						dioxide out.{" "}
					</p>
					<p id="p" align="justify">
						The mushrooms require some light but not direct sunlight. Enough
						light to read by is adequate such as on a kitchen counter. You can
						even place the entire kit + dome set-up in a bathroom shower stall
						or bathtub where you can control humidity, temperature and oxygen
						exchange.{" "}
						<i>
							Locate it where you will check it frequently to mist and to
							‘billow’ the dome to allow air exchange.
						</i>
					</p>
					<p id="p" align="justify">
						To maintain humidity mist inside the ‘tent’ enclosure use either a
						spray bottle (set to fine mist) or a small humidifier. Allow 3-5
						days for primordia formation. Perhaps longer if the conditions are
						not perfectly ideal. Observe frequently.
					</p>
					<p id="p" align="justify">
						Following the primordia formation (3-5 days) the{" "}
						<b>Lion’s Mane fruitbody/mushroom</b> will begin forming and emerge
						from the kit at where it is cut. At this stage the temperature
						should be warmer{" "}
						<b>
							18 to 24C or 65-75F and relative humidity should be approximately
							85% (90 to 95%).
						</b>
					</p>
					<p id="p" align="justify">
						Lion’s Mane typically grows into a roundish head with elongated
						spines and has a hanging habit of growing so try to arrange the kit
						on the edge of a rack on the tray inside the tent; this allows the
						relatively large, mushroom head to hang off the edge (‘perch’)
						inside the enclosure. In any case arrange the kit so that there is
						room for the mushroom head to freely develop. Once the fruitbody
						begins to form it grows quickly – 4 to 5 days.{" "}
					</p>
					<p id="p" align="justify">
						During <b>fruitbody development</b> do not mist directly on the
						fruitbody – mist inside the ‘tent’; you can also soak a clean sponge
						with water and leave that inside the tent to maintain humidity. Use
						a humidifier if available.
					</p>
					<p id="p" align="justify">
						Air exchange is important – 5 to 8 air exchanges per hour is ideal;
						you can augment oxygen exchange by billowing the perforated tent bag
						several times per day. Check the kit frequently and harvest
						mushrooms when fruitbody is formed and firm and spines have
						elongated but before the top becomes soft or discoloured. Lower the
						humidity a few hours prior to harvesting to avoid wet mushrooms and
						you can allow the mushroom to dry out a bit by removing the kit from
						the tent prior to harvesting. Harvest when the mushroom is still
						firm.{" "}
					</p>
					<p id="p" align="justify">
						Cut clumps off carefully. Wrap in wax or parchment paper and cook
						promptly – best to cook immediately. Store in paper bag or cardboard
						box refrigerated – drier mushrooms will keep longer. Slice
						transverse to the spines and saute carefully in clarified butter
						(ghee) or olive oil until golden brown. Garnish with sea salt or
						crystal salt; also try a touch of soy and or tamari. Garlic, onions,
						and almonds complement Lion’s Mane.{" "}
					</p>
					<p id="p" align="justify">
						After the first ‘flush’ the kit may spontaneously fruit again from
						the same puncture site or you can puncture again closer to the
						bottom where the kit retains more moisture and wait a few days for
						fruitbodies to start; you can adjust temperature and humidity to
						stimulate fruiting. Keep in mind that timelines can vary and slight
						adjustments in conditions can make a difference.{" "}
					</p>
					<p id="p" align="justify">
						To initiate successive “flushes” you can repeat this procedure:
						allow the kit to rest for a few days and then re-puncture if
						necessary; and humidity relatively high. You can repeat the above
						procedure several times or until all the nutrients have been used up
						and no more mushrooms form. At this point the kit will shrink and
						begin to dry out and harden and you will see that it is depleted.
						When the kit is depleted and no more mushrooms emerge you can break
						it up and use as top dressing. It makes an excellent soil
						conditioner.
					</p>
					<p id="p" align="justify">
						Recipes are available online and so are YouTube videos search for
						Lions Mane Mushroom Kit Tutorial
					</p>
					<p id="p" align="justify">
						Contact us by telephone at 416 963 5520 or at office@mycosource.com
						See pages 388-394 in Stamets, Growing Gourmet and Medicinal
						Mushrooms for comprehensive information about Lion’s Mane mushroom
						cultivation. He stresses careful handling and attention to detail to
						successfully cultivate Lion’s Mane mushrooms.
					</p>
					<p id="p" align="justify">
						<br />
						<img src={mycelium} alt="" width="500px" />
						<br />
						Updated pictures for the Lion’s Mane Kit Instructions will be coming
						soon
						<br />
						<br />
					</p>
					<p id="p" align="justify">
						FYI These are growth parameters recommended by Stamets in “Growing
						Gourmet and Medicinal Mushrooms”.
					</p>

					<p id="p" align="justify">
						<span style={{ marginLeft: "0px" }}>
							Primordia formation – 3 to 5 days
						</span>
						<br />
						<span style={{ marginLeft: "20px" }}>
							initiation temperature 10 to 15.6C / 50 to 60F
						</span>
						<br />
						<span style={{ marginLeft: "20px" }}>
							relative humidity 95-100%
						</span>
						<br />{" "}
						<span style={{ marginLeft: "20px" }}>
							5 to 8 oxygen/fresh air exchanges per hour
						</span>
						<br />{" "}
						<span style={{ marginLeft: "20px" }}>
							CO2 500 - 700 ppm; light requirement 500 – 1,000 lux
						</span>
					</p>

					<p id="p" align="justify" style={{ marginLeft: "0px" }}>
						<span style={{ marginLeft: "0px" }}>
							Fruitbody development – 4 to 5 days
						</span>
						<br />
						<span style={{ marginLeft: "20px" }}>
							temperature 18 to 24C / 65 to 75F
						</span>
						<br />
						<span style={{ marginLeft: "20px" }}>
							relative humidity 90 to 95%
						</span>
						<br />
						<span style={{ marginLeft: "20px" }}>
							to 8 oxygen/fresh air exchanges per hour
						</span>
						<br />
						<span style={{ marginLeft: "20px" }}>CO2 500 - 1,000 ppm;</span>
						<br />
						<span style={{ marginLeft: "20px" }}>
							light requirement 500 – 1,000 lux
						</span>
					</p>
					<p id="p" align="justify"></p>
					<p id="p" align="justify"></p>
				</div>
			</div>

			<div
				style={{
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>

			<div
				style={{
					margin: "30px 10px 30px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>
			<div style={{ height: "50px" }}></div>
		</div>
	);
};

export default LionsKit;
