import React from "react";
import Card from "./Card";
import FAQ from "./FAQ";

import shiitake34 from "./images/shiitake34.jpg";
import oyster34 from "./images/oyster34.jpg";
import lionsmane34 from "./images/lionsmane34.jpg";

const mostPopular = [
	{
		name: "Shiitake",
		benefits: "Lentinula edodes",
		image: shiitake34,
	},
	{
		name: "Tree Oyster",
		benefits: "Pleurotus oestreatus",
		image: oyster34,
	},
	{
		name: "Lion's Mane",
		benefits: "Hericium erinaceus",
		image: lionsmane34,
	},
];
function Home() {
	return (
		<div className="es-position">
			<div style={{ background: "#2D2D2D", height: "fit-content" }}>
				<h4
					style={{
						textAlign: "justify",
						paddingLeft: "50px",
						paddingRight: "50px",
					}}
				>
					Mycosource is a Canadian company specializing in sawdust spawn
					production for the outdoor hardwood log inoculation of Shiitake,
					Oyster, and Lion’s Mane mushrooms. We produce sawdust spawn for
					‘grow-your-own’ cultivators for outdoor log inoculation as well as
					sawdust kits and ‘grow blocks’ for indoor growers of all sizes. For
					cultivators who want to produce their own growing substrates we
					produce grain spawn, agar plate cultures, liquid syringe inoculants,
					and autoclave filter patch bags. We offer specialized tools and
					supplies, give workshops, share information and resources, and offer
					annual ‘pick-up & demo days’ at Fun Guy Farm located in Southern
					Ontario.
				</h4>
			</div>
			<div style={{ height: "fit-content" }}>
				<div
					style={{
						position: "relative",
						padding: "30px",
						background: "#474747",
						height: "100%",
					}}
				>
					<h3 style={{ textAlign: "center" }}>
						We produce sawdust spawn for these ‘wood’ mushroom species that grow
						on hardwoods
					</h3>
					<div className="most-popular-container">
						<div className="card-container">
							<Card mostPopular={mostPopular[0]} />
							<Card mostPopular={mostPopular[2]} />
							<Card mostPopular={mostPopular[1]} />
						</div>
					</div>
				</div>
			</div>
			<div style={{ background: "#2D2D2D", height: "fit-content" }}>
				<div style={{ background: "#2D2D2D", height: "fit-content" }}>
					<h4
						style={{
							textAlign: "justify",
							paddingLeft: "50px",
							paddingRight: "50px",
						}}
					>
						Spawn is living mycelium grown on hardwood sawdust. It is produced
						seasonally on a custom order basis and ships ‘just-in-time’ for the
						spring outdoor log inoculation season in April-May-June. Spawn, kits
						and ‘grow blocks’ as well as agar plates and syringes are living
						products prepared and shipped on a custom order basis from March
						until December.
					</h4>
				</div>
				<div
					style={{
						position: "relative",
						padding: "20px",
						background: "#3A3A3A",
						height: "100%",
					}}
				></div>
			</div>
			<div id="space20" style={{ height: "20px" }}></div>
		</div>
	);
}

export default Home;
