const SpawnAvailability = () => {
	return (
		<div className="es-position">
			<div
				style={{
					margin: "10px 10px 20px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			>
				<div
					id="cultivation-techniques-container"
					style={{
						position: "relative",
						background: "#2d2d2d",
						height: "fit-content",
						overflow: "hidden",
						width: "100%",
					}}
				>
					<h2 style={{ textAlign: "center" }}>
						Spawn Availability and Pricing
					</h2>
					<p style={{ textAlign: "left" }}>
						Sawdust Spawn <u>Availability</u>:
						<br />
						<ul>
							<li>
								Loose sawdust spawn is made to order and must be prepaid;
								pricing below is for all species{" "}
							</li>
							<li>
								To place an order see the ordering section/menu; keep in mind
								that spawn is a living product and shipping conforms to weather
								conditions{" "}
							</li>
							<li>
								We do not make spawn year-round – our season is March/April
								until July; Orders should be placed by March 15; we will take
								orders until the end of April; we require 4-6 weeks lead time{" "}
							</li>
							<li>
								We prepare spawn in March/April and ship just in time for
								outdoor log inoculation season in Canada which is mid April
								until end of July{" "}
							</li>
							<li>
								We ship via Canada Post; we can also ship via UPS or FedEx if
								specified{" "}
							</li>
							<li>
								When you place an order we will send you an invoice with full
								costs including shipping and ship date payable by e-transfer,
								cheque or paypal; payment details will come with the invoice{" "}
							</li>
							<li>Once paid we will send a receipt </li>
							<li>When we ship you will receive notification </li>
							<li>
								We offer ‘Pick-up & Demo Days’ at Fun Guy Farm for prepaid
								orders{" "}
							</li>
							<li>See the Ordering section/tab for full details</li>
						</ul>{" "}
						<br />
						Sawdust Spawn <u>Pricing</u>:
						<br />
						<br />
						Loose Sawdust Spawn comes in 2 sizes
						<br />
						<br />
						Small size bags
						<br />
						1.75 kilo per bag – enough to inoculate 12 to 15 logs that are
						4’long, depending on log diameter
						<br /> <t />
						<span style={{ marginLeft: "40px" }}>
							1-2 small bags Loose Sawdust Spawn @ 1.75 kilos each $30.00/bag
						</span>
						<br />
						<br />
						Regular size bags
						<br />
						2.5 kilo per bag - enough to inoculate 20 to 25 logs that are 4’
						long, depending on log diameter;
						<br />
						Note that Lion’s Mane requires a higher rate of inoculation
						<br />
						<span style={{ marginLeft: "40px" }}>
							1 regular bag Loose Sawdust Spawn @ 2.5 kilo $45.00
						</span>
						<br />
						<span style={{ marginLeft: "40px" }}>
							2 – 5 regular bags Loose Sawdust Spawn @ 2.5 kilos each $40.00/bag
						</span>
						<br />
						<span style={{ marginLeft: "40px" }}>
							{" "}
							6-10 regular bags Loose Sawdust Spawn @ 2.5 kilos each $35.00/bag
						</span>
						<br />
						<span style={{ marginLeft: "40px" }}>
							{" "}
							30 11-30 regular bags Loose Sawdust Spawn @ 2.5 kilos each
							$30.00/bag
						</span>
						<br />
						<span style={{ marginLeft: "40px" }}>
							{" "}
							30 + regular bags Loose Sawdust Spawn @ 2.5 kilos each - Contact
							us for quote
						</span>
					</p>
				</div>
			</div>

			<div
				style={{
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>

			<div
				style={{
					margin: "30px 10px 30px 10px",
					background: "#2D2D2D",
					height: "fit-content",
				}}
			></div>
			<div style={{ height: "50px" }}></div>
		</div>
	);
};

export default SpawnAvailability;
