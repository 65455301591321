import React, { useEffect, useState } from "react";
import "./Menu.css"; // Import your CSS file for styling

function Menu(props) {
	const [showMenu, setShowMenu] = useState(true);
	const [showCultivation, setShowCultivation] = useState(false);
	const [showSpawn, setShowSpawn] = useState(false);
	const [showSawdustKits, setShowSawdustKits] = useState(false);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [isXClicked, setIsXClicked] = useState(false);
	const [currentMenuItem, setCurrentMenuItem] = useState("Home");

	const toggleSawdustKits = () => {
		setShowMenu(true);
		setShowSawdustKits(!showSawdustKits);
	};

	const toggleCultivation = () => {
		setShowMenu(true);
		setShowSpawn(false);
		setShowCultivation(!showCultivation);
		currentMenuItem.includes("Shiitakekit") ||
		currentMenuItem.includes("OysteRkit") ||
		currentMenuItem.includes("Lionskit")
			? setShowSawdustKits(true)
			: setShowSawdustKits(false);
	};

	const toggleSpawn = () => {
		setShowCultivation(false);
		setShowMenu(true);
		setShowSpawn(!showSpawn);
	};

	const resetMenu = () => {
		setShowMenu(false);
		setShowSpawn(false);
	};

	const toggleMobileMenu = () => {
		setShowMobileMenu(!showMobileMenu);
		setIsXClicked(!isXClicked);
	};

	useEffect(() => {
		if (!showMenu) setShowMenu(true);
	}, [isXClicked]);

	useEffect(() => {
		const savedMenuStage = sessionStorage.getItem("menuState");
		//console.log("savedMenuStage::", savedMenuStage);
		if (savedMenuStage?.length > 0) {
			setCurrentMenuItem(savedMenuStage);
		}

		setShowMenu(props.showMenu);
		if (!showMenu) {
			setShowCultivation(false);
			setShowMobileMenu(false);
			setIsXClicked(false);
		}
	}, [props.showMenu]);

	return (
		<div className="menu">
			<div className="mobile-menu" onClick={toggleMobileMenu}>
				<div className={`bar1 ${isXClicked ? "rotate45" : ""}`}></div>
				<div className={`bar2 ${isXClicked ? "hide" : ""}`}></div>
				<div className={`bar3 ${isXClicked ? "rotate-45" : ""}`}></div>
			</div>
			{true && (
				<div
					className={
						showMobileMenu
							? "menu-items-container"
							: "menu-items-container-closed"
					}
				>
					<ul className="menu-items">
						<li
							id="li"
							className={
								currentMenuItem.includes("Home") ? "selectedMenuItem" : ""
							}
							onClick={() => {
								props.onUpdateMenuState("Home", "Welcome to MycoSource");
							}}
						>
							Home
						</li>
						<li
							id="li"
							className={
								currentMenuItem.includes("Mushroom") ||
								currentMenuItem.includes("Sawdust") ||
								currentMenuItem.includes("Oyster")
									? "selectedMenuItem"
									: ""
							}
							onClick={toggleCultivation}
						>
							Cultivation
						</li>
						{showCultivation && (
							<>
								<ul className="rundown">
									<li
										id="li"
										className={
											currentMenuItem.includes("Mushroom")
												? "selectedMenuItem"
												: ""
										}
										onClick={() => {
											resetMenu();
											props.onUpdateMenuState(
												"Mushroom",
												"Mushroom Cultivation"
											);
										}}
									>
										Log Based Outdoor
									</li>
									<li
										id="li"
										className={
											currentMenuItem.includes("Sawdust")
												? "selectedMenuItem"
												: ""
										}
										onClick={() => {
											resetMenu();
											props.onUpdateMenuState(
												"Sawdust",
												"Inoculation Procedure"
											);
										}}
									>
										Log Inoculation Procedure
									</li>
									<li
										id="li"
										className={
											currentMenuItem.includes("Oyster")
												? "selectedMenuItem"
												: ""
										}
										onClick={() => {
											resetMenu();
											props.onUpdateMenuState("Oyster", "Log management");
										}}
									>
										Log Management
									</li>
									<li
										id="li"
										className={
											currentMenuItem.includes("Shiitakekit") ||
											currentMenuItem.includes("OysteRkit") ||
											currentMenuItem.includes("Lionskit")
												? "selectedMenuItem"
												: ""
										}
										onClick={toggleSawdustKits}
									>
										Sawdust Kits
									</li>
								</ul>
								{showSawdustKits && (
									<ul className="shifted-box">
										<li
											id="li"
											className={
												currentMenuItem.includes("Shiitakekit")
													? "selectedMenuItem"
													: ""
											}
											onClick={() => {
												resetMenu();
												props.onUpdateMenuState(
													"Shiitakekit",
													"Shiitake Kit Instructions"
												);
											}}
										>
											Shiitake Kit Instructions
										</li>
										<li
											id="li"
											className={
												currentMenuItem.includes("OysteRkit")
													? "selectedMenuItem"
													: ""
											}
											onClick={() => {
												resetMenu();
												props.onUpdateMenuState(
													"OysteRkit",
													"Oyster Kit Instructions"
												);
											}}
										>
											Oyster Kit Instructions
										</li>
										<li
											id="li"
											className={
												currentMenuItem.includes("Lionskit")
													? "selectedMenuItem"
													: ""
											}
											onClick={() => {
												resetMenu();
												props.onUpdateMenuState(
													"Lionskit",
													"Lions’ Mane Kit Instructions"
												);
											}}
										>
											Lions’ Mane Kit Instructions
										</li>
									</ul>
								)}
							</>
						)}
						<li
							id="li"
							className={
								currentMenuItem.includes("Equipment") ? "selectedMenuItem" : ""
							}
							onClick={() => {
								props.onUpdateMenuState("Equipment", "Equipment and Supplies");
							}}
						>
							Equipment
						</li>
						<li
							id="li"
							className={
								currentMenuItem.includes("Availability")
									? "selectedMenuItem"
									: ""
							}
							/* onClick={() => {
								props.onUpdateMenuState(
									"Spawn",
									"Spawn Availability and Pricing"
								);
							}} */
							onClick={toggleSpawn}
						>
							Spawn & Kits
						</li>
						{showSpawn && (
							<>
								<ul className="rundownspawn">
									<li
										id="li"
										className={
											currentMenuItem.includes("SpawnAvailability")
												? "selectedMenuItem"
												: ""
										}
										onClick={() => {
											resetMenu();
											props.onUpdateMenuState(
												"SpawnAvailability",
												"Availability and Pricing – Spawn"
											);
										}}
									>
										Availability and Pricing – Spawn
									</li>
									<li
										id="li"
										className={
											currentMenuItem.includes("KitsAvailability")
												? "selectedMenuItem"
												: ""
										}
										onClick={() => {
											resetMenu();
											props.onUpdateMenuState(
												"KitsAvailability",
												"Availability and Pricing – Kits"
											);
										}}
									>
										Availability and Pricing – Kits
									</li>
								</ul>
							</>
						)}

						<li
							id="li"
							className={
								currentMenuItem.includes("Information")
									? "selectedMenuItem"
									: ""
							}
							onClick={() => {
								props.onUpdateMenuState("Information", "Information");
							}}
						>
							Information
						</li>
						<li
							id="li"
							className={
								currentMenuItem.includes("Ordering") ? "selectedMenuItem" : ""
							}
							onClick={() => {
								props.onUpdateMenuState("Ordering", "Ordering");
							}}
						>
							Ordering
						</li>
					</ul>
				</div>
			)}
		</div>
	);
}

export default Menu;
