import React, { useState } from "react";
import "./FAQ.css"; // Import your CSS file for styling

const faqData = [
	[
		{
			question: "What types of mushrooms do you grow?",
			answer: "We primarily grow shiitake, oyster, and lion's mane mushrooms.",
		},
		{
			question: "Are your mushrooms organic?",
			answer:
				"Yes, we use organic growing methods and materials to cultivate our mushrooms.",
		},
		{
			question: "How are your mushrooms harvested?",
			answer:
				"Our mushrooms are handpicked when they reach optimal maturity to ensure freshness and quality.",
		},
	],
	[
		{
			question: "Do you use any pesticides or chemicals?",
			answer:
				"No, we do not use any pesticides or harmful chemicals in our mushroom cultivation process.",
		},
		{
			question: "Can I visit your farm?",
			answer:
				"Yes, we offer farm tours where you can learn about our cultivation process and purchase fresh mushrooms directly from the farm.",
		},
		{
			question: "Do you offer mushroom growing kits?",
			answer:
				"Yes, we provide mushroom growing kits for customers interested in growing their own mushrooms at home.",
		},
	],
	[
		{
			question: "What are the health benefits of your mushrooms?",
			answer:
				"Our mushrooms are rich in nutrients and antioxidants, supporting immune function, heart health, and overall well-being.",
		},
		{
			question: "Are your mushrooms locally sourced?",
			answer:
				"Yes, all our mushrooms are grown locally in our farm, ensuring freshness and supporting the local community.",
		},
		{
			question: "How should I store your mushrooms?",
			answer:
				"Our mushrooms should be stored in a cool, dark place such as the refrigerator and used within a few days for optimal freshness.",
		},
	],
	[
		{
			question: "Are your mushrooms safe for people with allergies?",
			answer:
				"While our mushrooms are generally safe for consumption, individuals with mushroom allergies should avoid consuming them.",
		},
		{
			question: "Do you offer wholesale or bulk purchasing options?",
			answer:
				"Yes, we offer wholesale pricing for bulk orders. Please contact us for more information.",
		},
		{
			question: "How can I cook with your mushrooms?",
			answer:
				"Our mushrooms are versatile and can be used in a variety of dishes such as stir-fries, soups, salads, and more. Check out our recipes for inspiration!",
		},
	],
];

function FAQ() {
	const [openIndex, setOpenIndex] = useState(null);

	const rndIndex = Math.floor(Math.random() * faqData.length);

	const toggleAnswer = (index) => {
		setOpenIndex(openIndex === index ? null : index);
	};
	//console.log("faqData[0][0]", faqData[0][0].question);

	return (
		<div className="faq">
			<div className="question" onClick={() => toggleAnswer(0)}>
				<span>{faqData[rndIndex][0].question}</span>
				<div className={`arrow ${openIndex === 0 ? "open" : ""}`}></div>
			</div>
			{openIndex === 0 && (
				<div className="answer">{faqData[rndIndex][0].answer}</div>
			)}

			<div className="question" onClick={() => toggleAnswer(1)}>
				<span>{faqData[rndIndex][1].question}</span>
				<div className={`arrow ${openIndex === 1 ? "open" : ""}`}></div>
			</div>
			{openIndex === 1 && (
				<div className="answer">{faqData[rndIndex][1].answer}</div>
			)}

			<div className="question" onClick={() => toggleAnswer(2)}>
				<span>{faqData[rndIndex][2].question}</span>
				<div className={`arrow ${openIndex === 2 ? "open" : ""}`}></div>
			</div>
			{openIndex === 2 && (
				<div className="answer">{faqData[rndIndex][2].answer}</div>
			)}
		</div>
	);
}

export default FAQ;
