import React, { useState, useEffect } from "react";
import Home from "./Home";
import Blank from "./HomeBlank";
import Spawn from "./Spawn";
import Information from "./Information";
import Ordering from "./Old-Ordering";
import Equipment from "./Equipment";
import CultivationMushroom from "./CultivationMushroom";
import CultivationOyster from "./CultivationOyster";
import CultivationSawdust from "./CultivationSawdust";
import Shiitakekit from "./Shiitakekit";
import Oysterkit from "./Oysterkit";
import Lionskit from "./Lionskit";
import SpawnAvailability from "./SpawnAvailability";
import KitsAvailability from "./KitsAvailability";

function DynamicComponent(props) {
	const [componentName, setComponentName] = useState("Home");

	// Define a map of component names to their actual components
	const componentMap = {
		Home,
		Blank,
		Equipment,
		Spawn,
		Information,
		Ordering,
		Mushroom: CultivationMushroom,
		Oyster: CultivationOyster,
		Sawdust: CultivationSawdust,
		Shiitakekit,
		OysteRkit: Oysterkit,
		Lionskit,
		SpawnAvailability,
		KitsAvailability,
	};

	useEffect(() => {
		if (props?.currentPage) {
			setComponentName(props.currentPage);
		}
		//console.log("props:", props.currentPage);
	}, [props.currentPage]);

	useEffect(() => {
		/* const floatInHeader = document.getElementById("float-in-header");
		floatInHeader.style.top = "300px"; */
	}, [componentName]);
	const DynamicComponent = componentMap[componentName];
	//

	return (
		<div>
			<DynamicComponent />
		</div>
	);
}

export default DynamicComponent;
