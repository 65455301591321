import React, { useState, useEffect } from "react";

import Menu from "./Menu";

import DynamicComponent from "./DynamicComponent";
import "./styles.css"; // Import your CSS file where you define styles
import "./FAQ.css"; // Import your CSS file for styling
import logoCosource from "./images/logo-cosource.png";
import backGroundsl5 from "./images/inforest.jpg";

function App() {
	const [isNavbarFixed, setIsNavbarFixed] = useState(true);
	const [windowWidth, setWindowWidth] = useState(0);
	const [showMenu, setShowMenu] = useState(true);
	const [currentPage, setCurrentPage] = useState("Home");
	const [backGroundImage, setBackGroundImage] = useState(backGroundsl5);

	//

	const [floatingText, setFloatingText] = useState("");
	const [floatInText, setFloatInText] = useState("Mycosource Inc.");
	const [onTextChange, setOnTextChange] = useState(false);
	const [divHeight, setDivHeight] = useState("200px");

	useEffect(() => {
		const savedMenuStage = sessionStorage.getItem("menuState");
		const floatInText = sessionStorage.getItem("floatInText");
		const backGroundslX = sessionStorage.getItem("backGroundslX");
		//console.log("savedMenuStage:=", savedMenuStage);

		if (savedMenuStage?.length > 0) {
			setCurrentPage(savedMenuStage);
		}
		if (floatInText?.length > 0) {
			setFloatInText(floatInText);
		}
		if (backGroundslX?.length > 0) {
			setBackGroundImage(backGroundslX);
		}

		/*  Object.keys(globalVariables.colors).forEach((key) => {
			document.documentElement.style.setProperty(
				`--${key}`,
				globalVariables.colors[key]
			); 
			//console.log("key", globalVariables.colors[key]);
		}); */
	}, []);

	const toggleText = () => {
		//console.log("toggleText");
		setDivHeight("0px");

		setTimeout(() => {
			setDivHeight("200px");
			setOnTextChange(!onTextChange);
			setFloatingText(floatInText);
		}, 1000); // Adjust this time to match CSS transition duration
	};
	//
	useEffect(() => {
		const handleScroll = () => {
			const secondDiv = document.getElementById("second-div");
			const header = document.getElementById("header");
			const headerHeight = header.getBoundingClientRect().height;
			//setFloatInText(floatInText);
			if (secondDiv && header) {
				const secondDivTop = secondDiv.getBoundingClientRect().top;
				const headerBottom = header.getBoundingClientRect().bottom;
				setIsNavbarFixed(secondDivTop >= headerBottom ? true : false);
				if (secondDivTop <= headerHeight) {
					header.style.top = secondDivTop - headerHeight + "px";
					setShowMenu(false);
				} else {
					header.style.top = "0px";
					setShowMenu(true);
				}
				setWindowWidth(window.innerWidth);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
			setShowMenu(true);
		};
	}, []);

	const onUpdateMenuState = (menuState, floatInText) => {
		/* 	const floatInHeader = document.getElementById("float-in-header");
		floatInHeader.style.top = "0px"; */
		//console.log("menuState", menuState);
		setFloatInText(floatInText);
		setCurrentPage(menuState);
		sessionStorage.setItem("menuState", menuState);
		sessionStorage.setItem("floatInText", floatInText);
		//sessionStorage.setItem("backGroundslX", backGroundslX);
	};

	useEffect(() => {
		/* 	const floatInHeader = document.getElementById("float-in-header");
		floatInHeader.style.top = "0px"; */
		toggleText();
		console.log("floatInText", floatInText);
	}, [floatInText]);

	useEffect(() => {
		//console.log("showMenu", showMenu);
	}, [showMenu]);

	const onPageClick = (e) => {
		console.log("onPageClick", e.target);

		setShowMenu(!showMenu);
	};

	//console.log("currentPage:", currentPage);

	const onFooterTabClick = (e) => {
		//const footerTab = document.getElementById("footer-tab");
		const footer = document.getElementById("footer");
		footer.style.bottom = "300px";
	};

	return (
		<div
			className="App es-section"
			onClick={(e) => {
				onPageClick(e);
			}}
		>
			<div id="containera" className="container-fixed">
				<div
					id="header"
					style={{
						display: "flex",
						justifyContent: "space-between",
						border: "0px solid red",
						alignItems: "center",
					}}
				>
					<div
						className={isNavbarFixed ? "header" : "headerscroll"}
						style={{
							textAlign: "center",
							marginTop: "2px",
							border: "0px solid red",
							width: "35%",
							height: "100%",
						}}
					>
						<img src={logoCosource} alt="" width="250px" />
					</div>
					<div>
						<Menu showMenu={showMenu} onUpdateMenuState={onUpdateMenuState} />
					</div>
				</div>

				<div className="first-div" id="first-div"></div>
				<div id="second-div" className="second-div">
					<DynamicComponent currentPage={currentPage} />
				</div>

				<div
					id="footer"
					style={{
						display: "flex",
						bottom: "0px",
						width: "100%",
						height: "fit-content",
						backgroundColor: "#000",
						color: "gray",
					}}
				>
					<div id="copy" style={{ opacity: "0.5", width: "100%" }}>
						Copyright &copy; 2024 Mycosource Inc.
					</div>
					<div
						id="powered-by"
						style={{
							textAlign: "center",
							width: "30%",
							float: "right",
						}}
					></div>
				</div>
			</div>

			<div className="container">
				<div
					style={{ height: divHeight }}
					id="float-in-header"
					className="content"
				>
					<span>{floatingText}</span>
				</div>
			</div>
		</div>
	);
}

export default App;
